<template>
  <el-card class="box-card full-screen account-apply-list">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('accountType') ">
          <el-select v-model="queryForm.orgCum" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('org')" value="0"></el-option>
            <el-option :label="$t('customer')" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('checkStatus')">
          <el-select v-model="queryForm.status" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('checkPending')" value="0"></el-option>
            <el-option :label="$t('getApproved')" value="1"></el-option>
            <el-option :label="$t('notApproved')" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('applicationType')">
          <el-select v-model="queryForm.type" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('registerApply')" value="1"></el-option>
            <el-option :label="$t('logOutApply')" value="2"></el-option>
            <el-option :label="$t('lockApply')" value="3"></el-option>
            <el-option :label="$t('unlock Apply')" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <el-table-column prop="applyCode" fixed="left" :label="$t('applyCode')" width="160"></el-table-column>
        <el-table-column prop="orgCum" :label="$t('accountType')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.orgCum==0" class="text-success">{{$t('org')}}</span>
            <span v-if="scope.row.orgCum==1" class="text-primary">{{$t('customer')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="applyOrgCusId" :label="$t('orgCusName')" width="200">
          <template slot-scope="scope">
            <span :class="'text-'+scope.row.orgCum==0?'success':'primary'">{{scope.row.orgCusName}}({{scope.row.applyOrgCusCode}})</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" :label="$t('applicationType')" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.type==1" class="text-success">{{$t('registerApply')}}</span>
            <span v-if="scope.row.type==2" class="text-success">{{$t('logOutApply')}}</span>
            <span v-if="scope.row.type==3" class="text-success">{{$t('lockApply')}}</span>
            <span v-if="scope.row.type==4" class="text-success">{{$t('unlock Apply')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="applyRemark" :label="$t('applayRemark')" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="verifyOrgCode" :label="$t('checkOrg')" width="180">
          <template slot-scope="scope">
            {{scope.row.verifyOrgName}}({{scope.row.verifyOrgCode}})
          </template>
        </el-table-column>
        <!-- <el-table-column prop="verifyUser" :label="$t('auditor')" width="160"></el-table-column> -->
        <el-table-column prop="status" :label="$t('checkStatus')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-primary">{{$t('checkPending')}}</span>
            <span v-if="scope.row.status==1" class="text-success">{{$t('getApproved')}}</span>
            <span v-if="scope.row.status==2" class="text-danger">{{$t('notApproved')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="casue" :label="$t('reason')" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="verifyRemark" :label="$t('checkRemark')" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="verifyTime" :label="$t('verifyTime')" width="200">
          <template slot-scope="scope">{{scope.row.verifyTime && $manba(scope.row.verifyTime).format('ymdhms')}}</template>
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('creationTime')" width="200">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('extend')" width="160">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status==0||scope.row.status==2" size="mini" @click="showDrawer(scope.row)" type="primary" :disabled="scope.row.status==2">
              {{$t('check')}}
            </el-button>
            <!-- 个人账户不需要配置 -->
            <el-button v-if="scope.row.status==1" :disabled="scope.row.orgCum==1" size="mini" @click="businessConfig(scope.row)">
              {{$t('businessConfig')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="$t('registerCheck')" :visible.sync="drawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="1000">
      <account-apply-check :rowData="rowData" :success-callback="drawerInvisible" />
    </el-drawer>

    <el-drawer :title="$t('businessConfig')" :visible.sync="businessConfigVisible" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="1000">
      <business-config-form :auditInfo="auditInfo" :success-callback="businessConfigInvisible" />
    </el-drawer>
  </el-card>
</template>
<script>
import businessConfigForm from '@/components/accountApplyList/BusinessConfigForm'
import accountApplyCheck from '@/components/accountApplyList/AccountApplyCheck'

import accountApply from '@/api/accountApply'
export default {
  name: "accountApplyList",
  components: { businessConfigForm, accountApplyCheck },
  data () {
    return {
      queryForm: {},
      /**审核form */
      rowData: {},
      /**业务配置form */
      auditInfo: {},
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
      //显示加载中
      isLoading: false,

      //右侧弹框
      drawer: false,
      /**业务配置可见 */
      businessConfigVisible: false,
    };
  },
  methods: {
    getList () {
      let _this = this;
      _this.isLoading = true;

      accountApply.getPage({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.tableData = res.data || {};
          _this.isLoading = false;
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    query () {
      this.getList();
    },

    /**打开审核弹框 */
    showDrawer (row) {
      this.rowData = row;
      this.drawer = true;
    },
    /**关闭审核弹框 */
    drawerInvisible (val) { this.drawer = val; },

    /**业务配置弹框 */
    businessConfig (row) {
      this.businessConfigVisible = true;
      this.auditInfo = row;
    },
    /**关闭业务配置弹框 */
    businessConfigInvisible (val) { this.businessConfigVisible = val; }
  },
  created () { },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getList();
  },
}
</script>

<style lang="less">
.account-apply-list {
  #org-info-from {
    .el-col-8 {
      margin-bottom: 31px;
    }
  }

  .testes {
    animation: identifier 300ms;
  }
  @keyframes identifier {
    0% {
      height: 0px;
    }
    100% {
      height: 100px;
    }
  }
}
</style>