<template>
  <el-form ref="configForms" :rules="rules">
    <div v-for="t in configForms" :key="t.id">
      <el-divider content-position="left">{{$t(t.name)}}</el-divider>
      <el-row :gutter="20" v-for="t2 in t.children" :key="t2.id">
        <el-col :span="6">
          <el-form-item :label="$t('businessName')">
            <el-input size="medium" :placeholder="$t(t2.name)" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('remunerationRules')" :prop="t2.haveRemuneration==0?'remunerationId':''">
            <el-select size="medium" v-model="t2.remunerationId" :disabled="t2.haveRemuneration!=0" :placeholder="t2.haveRemuneration!=0?$t('notChargeNotConfig'):$t('pleaseSelectRemunerationTule')">
              <template v-for="op in remunerationList">
                <el-option v-if="t2.businessCode==op.remunerationCode" :key="op.id" :label="`${op.name} (${op.sourceSettlementAmount}${(op.settlementRules==1?'%':'')}) (${op.platformSettlementAmount}${(op.settlementRules==1?'%':'')})`" :value="op.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="9">
          <el-form-item :label="$t('serviceFeeRules')" :prop="t2.haveServiceCharge==0?'serviceChargeId':''">
            <el-select size="medium" v-model="t2.serviceChargeId" :disabled="t2.haveServiceCharge!=0" multiple :placeholder="t2.haveServiceCharge!=0?$t('notChargeNotConfig'):$t('pleaseSelectServiceChargeTule')">
              <template v-for="op in serviceChargeList">
                <el-option v-if="t2.businessCode==op.serviceChargeCode" :key="op.id" :label="`${op.name} (${op.minValue}~${op.maxValue}) (${op.settlementAmount}${(op.settlementRules==1?'%':'')})`" :value="op.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <el-button type="primary" class="bottom-button" :loading="isLoading" @click="onSubmit('configForms')">{{$t('submit')}}</el-button>
  </el-form>
</template>

<script>
import business from '@/api/business'
import org from '@/api/org'
export default {
  props: ['auditInfo', 'successCallback'],
  data () {
    return {
      isLoading: false,
      businessList: [],
      configForms: [],
      remunerationList: new Array(),
      serviceChargeList: new Array(),
    }
  },
  computed: {
    rules () {
      return {
        remunerationId: [{ type: Array, required: true, message: this.$t('notNull'), trigger: 'blur' }],
        serviceChargeId: [{ type: Array, required: true, message: this.$t('notNull'), trigger: 'blur' }],
      }
    }
  },
  mounted () {
    this.getBusinessByRoleId(this.auditInfo.applyOrgCusId);
    this.getRemunerationList();
    this.getServiceChargeList();
  },
  methods: {
    /**根据角色ID获取业务 */
    getBusinessByRoleId (roleId) {
      let _this = this;
      business.getList({
        param: { roleId: roleId || 1 },
        success: res => {
          if (res.code != 200) return;
          let list = JSON.parse(JSON.stringify(res.data));
          let tree = list.filter(function (t) { return t.parentId == 0 });
          for (let i = 0; i < tree.length; i++) {
            tree[i].children = list.filter(function (t) { return t.parentId == tree[i].id });
            for (let ii = 0; ii < tree[i].children.length; ii++) {
              tree[i].children[ii].remunerationId = null;
              tree[i].children[ii].serviceChargeId = null;
            }
          }
          _this.configForms = tree;
          _this.businessList = res.data;

          this.getAlreadyBinding(_this.configForms, roleId);
        }
      })
    },
    /**获取酬金列表 */
    getRemunerationList () {
      let _this = this;
      business.getRemunerationList({
        success: res => {
          if (res.code != 200) return;
          _this.remunerationList = res.data;
        }
      })
    },
    /**获取手续费列表 */
    getServiceChargeList () {
      let _this = this;
      business.getServiceChargeList({
        success: res => {
          if (res.code != 200) return;
          _this.serviceChargeList = res.data;
        }
      })
    },
    /**获取机构已经绑定的手续费\酬金 */
    getAlreadyBinding (forms, orgId) {
      org.listOrgSerAndRem({
        param: { orgId: orgId },
        success: res => {
          if (res.code != 200) return;

          let r = res.data.remunerationList;
          let s = res.data.serviceChargeList;
          //赋值业务对应的酬金
          for (let i = 0; i < r.length; i++) {
            let ri = r[i]
            for (let i2 = 0; i2 < forms.length; i2++) {
              let form = forms[i2].children.find(function (ci) { return ci.businessCode == ri.remunerationCode })
              if (form) form.remunerationId = ri.id;
            }
          }
          //赋值业务对应的手续费
          //s 所有手续费  forms 当前用户可用业务 
          for (let i = 0; i < s.length; i++) {
            let si = s[i]
            for (let i2 = 0; i2 < forms.length; i2++) {
              let form = forms[i2].children.find(function (ci) { return ci.businessCode == si.serviceChargeCode })
              if (form) form.serviceChargeId.push(si.id);
            }
          }
        }
      })
    },
    onSubmit (formRef) {
      let _this = this;
      let r = new Array();
      let s = new Array();
      //提取form数组里面的手续费/酬金,生成请求参数
      for (let i = 0; (this[formRef] && i < this[formRef].length); i++) {
        let t = this[formRef][i];
        for (let ii = 0; (t.children && ii < t.children.length); ii++) {
          let c = t.children[ii];
          if (c.remunerationId) r.push(c.remunerationId)
          if (c.serviceChargeId && c.serviceChargeId.length > 0) s.push(c.serviceChargeId)
        }
      }
      org.bindingOrgSR({
        param: {
          orgId: _this.auditInfo.applyOrgCusId,
          serviceChargeIds: s.toString(),
          remunerationIds: r.toString()
        },
        success: res => {
          if (res.code == 200) {
            _this.$message.success(_this.$t('operateSuccessfully'))
            _this.successCallback(false);
          }
          else {
            _this.$message.warning(_this.$t(res.msg));
          }
        }
      })
    }
  },

}
</script>

<style lang="less" scoped>
</style>