<template>
  <div>
    <el-steps :active="stepsActive" align-center finish-status="success">
      <el-step :title="$t('applyInfo')"></el-step>
      <el-step :title="$t('check')"></el-step>
      <el-step :title="$t('accomplish')"></el-step>
    </el-steps>

    <el-form v-if="stepsActive==0&&rowData.orgCum==0" :model="orgInfoFrom" label-position="top" class="have-steps" id="org-info-from">
      <el-row :gutter="20">
        <el-col :span="16">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item :label="$t('org')+$t('speciesName')">
                <el-input size="medium" v-model="orgInfoFrom.name" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('homeCountry')+'/'+$t('region')">
                <el-cascader size="medium" v-model="orgInfoFrom.countryRegion" :options="regionTree" clearable :props="{ value: 'id', label: 'name' }" disabled></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('businessLicense')+$t('number')">
                <el-input size="medium" v-model="orgInfoFrom.businessLicenseNo" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('linkPhone')">
                <el-input size="medium" v-model="orgInfoFrom.linkPhone" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('bankName')">
                <el-input size="medium" v-model="orgInfoFrom.bankName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('bankAccount')">
                <el-input size="medium" v-model="orgInfoFrom.bankAccount" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('org')+$t('address')">
                <el-input size="medium" v-model="orgInfoFrom.orgAddress" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('legalPerson')+$t('lastName')">
                <el-input size="medium" v-model="orgInfoFrom.legalpersonSurname" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('firstName')">
                <el-input size="medium" v-model="orgInfoFrom.legalpersonName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('legalPerson')+$t('idNumber')">
                <el-input size="medium" v-model="orgInfoFrom.legalpersonCard" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('remark')">
                <el-input size="medium" v-model="orgInfoFrom.remark" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('apply')+$t('explain')">
                <el-input size="medium" v-model="orgInfoFrom.applyRemark" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('businessLicense')+$t('image')" style="margin-bottom:35px">
            <el-image :src="orgInfoFrom.businessLicensePic" style="width:100%;height:175px;border-radius:5px;"></el-image>
          </el-form-item>
          <el-form-item :label="$t('legalPerson')+$t('image')">
            <el-image :src="orgInfoFrom.legalpersonImg" style="width:100%;height:175px;border-radius:5px;"></el-image>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form v-if="stepsActive==0&&rowData.orgCum==1" :model="userInfoFrom" label-position="top" class="have-steps" id="org-info-from">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item :label="$t('loginAccount')">
            <el-input size="medium" v-model="userInfoFrom.loginName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('homeCountry')+'/'+$t('region')">
            <el-cascader size="medium" v-model="userInfoFrom.countryRegion" :options="regionTree" clearable :props="{ value: 'id', label: 'name' }" disabled></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('firstName')">
            <el-input size="medium" v-model="userInfoFrom.name" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('lastName')">
            <el-input size="medium" v-model="userInfoFrom.surname" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('linkPhone')">
            <el-input size="medium" v-model="userInfoFrom.phoneNo" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('idNumber')">
            <el-input size="medium" v-model="userInfoFrom.idCard" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('sex')">
            <el-input size="medium" v-model="userInfoFrom.gender" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('maritalStatus')">
            <el-input size="medium" v-model="userInfoFrom.marryStatus" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('address')">
            <el-input size="medium" v-model="userInfoFrom.address" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('remark')">
            <el-input size="medium" v-model="userInfoFrom.remark" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('idCardFront')" style="margin-bottom:35px">
            <el-image :src="userInfoFrom.cardImg1" style="width:100%;height:175px;border-radius:5px;"></el-image>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('idCardBack')">
            <el-image :src="userInfoFrom.cardImg2" style="width:100%;height:175px;border-radius:5px;"></el-image>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-form v-if="stepsActive==1" :model="editForm" :rules="rules" ref="editForm" class="have-steps">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item :label="$t('applyCode')">
            <el-input size="medium" v-model="editForm.applyCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('org') +' / '+ $t('customer')">
            <el-select size="medium" v-model="editForm.orgCum" disabled style="width:100%">
              <el-option :label="$t('org')" :value="0"></el-option>
              <el-option :label="$t('customer')" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('applyId')">
            <el-input size="medium" v-model="editForm.applyOrgCusId" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('applicationType')">
            <el-select size="medium" v-model="editForm.type" disabled style="width:100%">
              <el-option :label="$t('registerApply')" :value="1"></el-option>
              <el-option :label="$t('logOutApply')" :value="2"></el-option>
              <el-option :label="$t('lockApply')" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-divider content-position="left">{{$t('checkResult')}}</el-divider>
          <el-form-item prop="status">
            <el-radio-group size="medium" v-model="editForm.status" prop="status">
              <el-radio border :label="1">{{$t('getApproved')}}</el-radio>
              <el-radio border :label="2">{{$t('notApproved')}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('checkRemark')">
            <el-input size="medium" type="textarea" rows="3" v-model="editForm.verifyRemark" :placeholder="$t('checkRemark')"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span v-loading="true" v-if="stepsActive==2" class="max-loading"></span>

    <div v-if="stepsActive>=3" class="accomplish">
      <el-progress type="circle" v-if="stepsActive==3" :stroke-width="10" :percentage="percentage" :width="200" status="success"></el-progress>
      <div v-if="stepsActive==3" style="text-align:center;color:#67C23A;font-size:40px">{{$t('submit')+$t('success')}}</div>
      <el-progress type="circle" v-if="stepsActive==4" :stroke-width="10" :percentage="percentage" :width="200" status="exception"></el-progress>
      <div v-if="stepsActive==4" style="text-align:center;color:#F56C6C;font-size:40px">{{$t('submit')+$t('failing')}}</div>
    </div>

    <div class="bottom-button">
      <el-button size="medium" v-if="stepsActive>0&&stepsActive<2" :loading="isLoading" @click="--stepsActive">{{$t('prevStep')}}</el-button>
      <el-button size="medium" v-if="stepsActive==4" :loading="isLoading" @click="stepsActive=1">{{$t('prevStep')}}</el-button>
      <el-button size="medium" v-if="stepsActive<1&&stepsActive<2" :loading="isLoading" @click="++stepsActive">{{$t('nextStep')}}</el-button>

      <el-button size="medium" type="primary" v-if="stepsActive==1" :loading="isLoading" @click="onSubmit('editForm')">{{$t('subminCheckResult')}}</el-button>
    </div>
  </div>
</template>

<script>
import verification from '@/assets/uilt/verification'
import accountApply from '@/api/accountApply'
import org from '@/api/org'
import country from '@/api/country'
import customer from '@/api/customer'

export default {
  props: ['rowData'],
  data () {
    return {
      /**申请详细信息 */
      orgInfoFrom: {},
      /**客户账户申请 */
      userInfoFrom: {},

      /**区域树 */
      regionTree: [],
      isLoading: false,
      /**步骤条进度 */
      stepsActive: 0,
      //环形进度条进度%
      percentage: 0,
    }
  },
  computed: {
    rules () {
      let v = new verification(this)
      return {
        status: [{ required: true, validator: v.radio, trigger: 'blur' }],
        remunerationId: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        serviceChargeId: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    },
    /**行数据 */
    editForm () { return this.rowData }
  },
  methods: {
    /**获取区域树 */
    getRegionList () {
      let _this = this;
      country.getList({
        success: res => {
          let parent = res.data.filter(function (t) { return t.parentId == 0; });
          parent.forEach(function (t) {
            let children = res.data.filter(function (t2) { return t2.parentId == t.id; });
            t.children = children
          });
          _this.regionTree = parent;
        }
      })
    },

    /**获取机构用户提交的申请信息 */
    getOrgInfo (orgId) {
      let _this = this;
      org.getOrgInfoPage({
        param: { orgId: orgId },
        success: function (res) {
          let data = res.data.records[0];
          data.countryRegion = [data.country, data.region];

          _this.orgInfoFrom = data;
        }
      })
    },
    /**获取个人用户提交的申请信息 */
    getUserPage (id) {
      let _this = this;
      customer.getPage({
        param: { id: id },
        success: (res) => {
          let data = res.data.records[0];
          // data.businessLicensePic = require('@/assets/image/login/login.jpg')
          // data.legalpersonImg = require('@/assets/image/login/login2.jpg')
          data.countryRegion = [data.country, data.region];

          data.gender = this.$t('sex' + data.gender)
          data.marryStatus = this.$t('marryStatus' + data.marryStatus)
          data.cardImg1 = data.idImg.split(',')[0]
          data.cardImg2 = data.idImg.split(',')[1]

          _this.userInfoFrom = data;
        }
      })
    },
    /**审核结果提交 */
    onSubmit (formRef) {
      let _this = this;
      this.$refs[formRef].validate(valid => {
        if (!valid) return false;
        this.stepsActive = 2;

        let data = { id: this.editForm.id, verifyRemark: this.editForm.verifyRemark, casue: this.editForm.casue || '', status: this.editForm.status }

        _this.isLoading = true;
        accountApply.updateStatus({
          param: data,// _this.editForm,
          success: res => {
            if (res.code == 200) {
              _this.$message.success(_this.$t('operateSuccessfully'));
              _this.stepsActive = 3;
            }
            else {
              _this.$message.warning(_this.$t(res.msg));
              _this.editForm.status = 0;
              _this.stepsActive = 4;
            }
            _this.$nextTick(() => { _this.percentage = 100; })
            _this.isLoading = false;
          }
        })
      })
    },
  },
  mounted () {
    this.getRegionList();
    if (this.rowData.orgCum == 0) {
      this.getOrgInfo(this.rowData.applyOrgCusId);
    }
    else if (this.rowData.orgCum == 1) {
      this.getUserPage(this.rowData.applyOrgCusId)
    }

  }
}
</script>

<style>
</style>